.scores-component {
  .score-content {
    padding: 20px;
    position: relative;

    .lock-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: var(--blue900_60);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding-top: 50px;

      .free-account {
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        color: var(--white900);
        margin: 22px 0 24px;
      }

      .create-account-btn {
        font-size: 14px;
        background-color: var(--green800);
        padding: 11px 24px;
        margin-bottom: 41px;
      }
    }
  }

  .plan-status {
    background-color: var(--blue500);
    padding: 20px;
    margin-top: 25px;

    .lock-container {
      color: var(--white900);
      font-size: 16px;

      .lock-content {
        width: 48px;
        height: 48px;
        background-color: var(--main-color);
        margin-right: 10px;
      }

      .plan-status-title {
        font-size: 12px;
        opacity: 0.6;
      }
    }

    .upgrade-btn {
      padding: 11px 10px;
      background-color: var(--green800);
      font-size: 12px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 991px) {
  .scores-component {
    .plan-status {
      padding: 10px;

      .upgrade-btn {
        font-size: 11px;
      }
    }
  }
}
