.top-menu-component {
  position: fixed;
  z-index: 10;
  background: var(--blue900);
  width: 100%;
  height: 40px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  .dot {
    opacity: 0.2;
    margin: 0 20px;
  }

  .tag {
    color: white;
  }

  .make-it-sense {
    color: var(--blue800);
  }
}

@media screen and (min-width: 992px) {
  .top-menu-component {
    
  }
}

@media screen and (max-width: 991px) {
  .top-menu-component {
   
  }
}