.question-answer-flow-component {
  .tags-content {
    height: 60px;
  }

  .questions-answer-content {
    position: relative;
    padding: 30px;
    background-color: #F9FAFB;

    .frame-icon {
      position: absolute;
      right: 40px;
      top: 40px;
      cursor: pointer;
    }

    .question-content {
      padding: 30px 0;

      .question-img {
        width: 100%;
      }
    }

    .btn-content {
      margin-top: 36px;

      .back-btn {
        color: var(--main-color);
        background-color: var(--blue800_10);
      }

      .skip-btn {
        margin-right: 10px;
        background-color: #F9FAFB;
        color: var(--main-color);
      }

      .step-num {
        font-size: 12px;
        opacity: 0.6;

        strong {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .question-answer-flow-component {
    .questions-answer-content {
      margin-top: 30px;
    }
  }
}