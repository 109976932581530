.signup {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .sub-description {
    font-size: 12px;
    margin-left: 10px;
  }

  .coupon-code-input {
    background-color: var(--blue800_10);
  }

  .apply-btn {
    width: 100%;
  }

  .share-friend-content {
    gap: 10px;
    margin-top: 15px;

    .additional-off {
      font-size: 14px;
      color: var(--blue900_60);
    }
  }

  .sub-title {
    margin-top: -20px;
    margin-bottom: 20px;
    color: var(--blue900_60);
  }

  .policy-content {
    color: var(--blue900_60);
    margin-bottom: 20px;
  }

  .have-account {
    color: var(--blue900_60);
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .signup {
    gap: 10px;

    .first-name,
    .coupon-code-input {
      margin-bottom: 10px;
    }
  }
}