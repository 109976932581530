

.custom-toggle-component {
    position: relative;
    border-radius: 22.5px;
    cursor: pointer;
    background: var(--red900_10);
    margin-top: 20px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    // border: 1px solid $gray100;

    &.correct {
        background-color: var(--green900_10);
        .handle {
            background-color: var(--green900);
        }
    }
  
    .handle {
        position: absolute;
        top: 3px;
        left: 49px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: var(--red900);
        transition: left 0.15s;
        -webkit-transition: left 0.15s;
        -moz-user-select: none;
        -webkit-user-select: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 2;
    }

    .theme {
        color: var(--black900);
        font-weight: bold;
        position: absolute;
        font-size: 13px;
        left: 12px;
        top: calc(50% - 9px);
    }
  
    &.disable {
      background: grey !important;

      .theme {
        display: none;
      }
    }
  
    &.off {
        .theme {
            color: var(--blue900);
            left: 50px;
        }
        
        .handle {
            left: 3px;
        }
    }
}

@media screen and (min-width: 992px) {
    .custom-toggle-component {
        width: 105px;
        height: 39px;

        .handle {
            top: 3px;
            left: 69px;
            width: 33px;
            height: 33px;
        }
      
        &.off {
            .handle {
                left: 3px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .custom-toggle-component {
        width: 105px;
        height: 30px;

        .handle {
            top: 2px;
            left: 76px;
            width: 26px;
            height: 26px;
        }
      
        &.off {
            .handle {
                left: 2px;
            }
        }
    }
}