.practice-modal-component {
  color: var(--blue900);

  .modal-dialog {
    width: 100%;
    margin: 0;
    height: 100%;
    max-width: 100%;

    .modal-content {
      border: none;
      outline: none;
      background: none;
      position: relative;
      height: 100%;

      .close-btn {
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        transform: rotate(45deg);
      }      
    }
  }

  .practice-content {
    display: flex;
    margin: auto;
    background: white;
    position: relative;
    width: 100%;    

    .modal-title {
      font-weight: 700;
      font-size: 24px;
    }

    .cancel-btn {
      margin-right: 10px;
      background-color: #F9FAFB;
      color: var(--main-color);
    }

    .practice-pop-up-form {
      background-color: var(--gray700_50);
    }
  }
}

@media screen and (min-width: 992px) {
  .modal-content {
    padding: 0 30px;
  }

  .practice-content {
    max-width: 580px;
    // height: 500px;

    .practice-main-content {
      padding: 150px 32px 32px;

      .girl-img {
        position: absolute;
        top: -235px;
        left: 110px;
      }

      .submit-btn {
        position: relative;

        .arrow-icon {
          position: absolute;
          right: 150px;
          bottom: -25px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .modal-content {
    padding: 0 15px;
  }

  .practice-content {
    max-width: 345px;
    flex-direction: column;
    max-height: 400px;
    height: 100%;

    .practice-main-content {
      padding: 20px;

      .modal-title {
        font-size: 18px;
      }
    }
  }
}