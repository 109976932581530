.decide-now-component {
  color: var(--white900);
  background-color: var(--blue300);

  h1 {
    white-space: pre-line;

    strong {
      color: var(--green800);
    }
  }

  h3 {
    strong {
      color: var(--green800);
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    margin-left: 20px;
  }

  .decide-now-content {
    .left-content {
      height: 100%;
      padding-left: 0;

      .decide-content {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .detail-list {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .detail-item {
            display: flex;
            gap: 10px;
            font-size: 18px;
          }
        }

        .act-now-section {
          border: solid 1px var(--red300);
          padding: 30px 25px;
          font-size: 20px;
          font-style: italic;
        }

        .access-section {
          font-size: 24px;
        }

      }

      .upgrade-btn {
        margin-top: 40px;
        background-color: var(--green800);
        color: var(--blue900);
        border-radius: 8px;
      }
    }

    .right-content {
      display: flex;
      justify-content: flex-end;

      .decide-right-img {
        max-width: 420px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .decide-now-component {
    padding: 15px;

    p {
      font-size: 14px;
      font-weight: 400;
    }

    .decide-now-content {
      height: 100%;

      .left-content {
        padding: 15px;
        order: 2;

        .star-icon {
          width: 20px;
        }
      }

      .right-content {
        padding: 20px;

        .decide-right-img {
          width: 100%;
        }
      }
    }
  }
}