.search-input-component {
  margin-top: 30px;
  position: relative;

  input {
    width: 100%;
    border: none;
    font-size: 16px;
    height: 60px;
    padding-left: 45px;
    color: var(--grey200);
    border: solid 1px var(--gray700);

    &::placeholder {
      color: var(--gray100);
    }

    &:hover {
      border: solid 1px var(--gray100);
    }

    &:focus-visible {
      outline: none;
    }
  }

  .search-icon {
    position: absolute;
    left: 15px;
    top: 16px;
  }
}

@media screen and (max-width: 991px) {
  .search-input-component {
    input {
      font-size: 14px;
      height: 50px;
    }

    svg {
      width: 16px;
    }

    .search-icon {
      position: absolute;
      left: 15px;
      top: 12px;
    }
  }
}