.phone-input-component {
  width: 100%;
  position: relative;

  .phone-input-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--gray700);

    &.label-container {
      flex-direction: column;
      align-items: flex-start;

      .label {
        font-size: 12px;
        color: var(--blue900);
        opacity: 0.6;
        margin: 2px 0;
      }
    }

    input {
      width: 100%;
    }

    .phone {
      border-width: 0px;
      color: var(--white);

      &:focus {
        box-shadow: none;
      }
    }

    .react-tel-input {
      background-color: var(--gray700);
    }

    .selected-flag {
      &:hover {
        background-color: none;
      }
    }

    .drop-down {
      color: var(--black900);
    }

    .buttonClass {
      background-color: transparent;
      border-width: 0px;
    }

    ::placeholder {
      color: var(--blue900);
    }
  }
}

@media screen and (min-width: 992px) {
  .phone-input-component {
    .phone-input-container {
      height: 60px;
      padding-left: 15px;

      input {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .phone-input-component {
    .phone-input-container {
      height: 50px;
      padding-left: 17px;

      &.label-container {
        .label {
          margin: 5px 5px 3px 0;
          font-size: 12px;
        }
      }

      input {
        font-size: 14px;
        height: 20px;
      }

      .drop-down {
        width: 254px;
        z-index: 3;
      }
    }
  }
}