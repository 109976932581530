.question-answer-page {
  // padding: 10px 0 80px;
  padding: 80px 0;
  position: relative;

  .icon-close {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 15px;
    transform: rotate(90deg);
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: 991px) {
    padding: 50px 0;

    h2 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
}