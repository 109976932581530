.up-selling-item-component {
  z-index: 5;

  .up-sell-img {
    height: 100px;
  }

  .up-selling-detail {
    background-color: var(--gray800);
    justify-content: space-between;
    padding: 10px 15px;
    width: 100%;

    &.active {
      background-color: var(--green900_15);
    }

    .title-description {
      max-width: 230px;
      font-size: 14px;

      .title {
        font-weight: 700;
      }

      .description {
        line-height: 15px
      }
    }

    .origin-price {
      opacity: 0.5;
      text-decoration: line-through;
    }

    .price {
      font-size: 18px;
    }
  }
}