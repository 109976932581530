.plan-page {
  padding: 50px 0 50px;

  h1 {
    font-size: 44px;
    font-weight: 700;
  }

  .description {
    font-size: 20px;
    font-weight: 400;
  }

  .plan-content {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    padding: 50px 0;
  }
}


@media screen and (max-width: 991px) {
  .plan-page {
    padding: 15px;

    h1 {
      font-size: 34px;
      font-weight: 700;
    }

    .description {
      font-size: 16px;
    }

    .plan-content {
      padding: 30px 0;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}