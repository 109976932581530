.home-start-component {
  padding: 40px 0;
  color: var(--white900);

  h1 {
    font-size: 44px;
    font-weight: 700;
    white-space: pre-line;
    line-height: 60px;
    padding: 5px 0 10px;
  }

  p {
    font-size: 16px;
    color: var(--white700);
    line-height: 25.6px;
    white-space: pre-line;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    margin-right: 15px;
  }

  .home-start-container {
    background: no-repeat url('../../../Assets/Images/Landing/Start/home-start-bg.png');
    background-position: center;

    .left-content {
      padding: 10px 40px;

      .opacity {
        opacity: 0.8;
      }

      .small-logo {
        margin-top: 40px;
        width: 67px;
      }
    }

    .right-content {
      overflow: hidden;
      padding-right: 0;

      .home-img {
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .home-start-component {
    padding: 30px 0;

    h1 {
      font-size: 38px;
      text-align: center;
      line-height: 50px;
    }

    p {
      text-align: center;
    }

    span {
      text-align: center;
    }
  }
}