.believe-practice-component {
  color: var(--blue900);

  p {
    font-size: 16px;
    font-weight: 400;
  }

  .believe-practice-content {
    max-width: 1052px;

    .left-content {
      height: 100%;
      padding-left: 0;
      margin-top: auto;
      margin-bottom: auto;

      .video-tag {
        font-size: 14px;
        margin-top: 20px;
        color: var(--blue900);
        text-align: center;
        font-weight: 500;
      }
    }

    .signup-btn {
      margin-top: 30px;
    }
  }
}

@media screen and (min-width: 992px) {
  .believe-practice-component {
    background: url('../../../Assets/Images/Landing/practice-bg.png') no-repeat;
    background-position: center top;
    background-size: 100% auto;
    background-color: #F9FAFB;
  }
}

@media screen and (max-width: 991px) {
  .believe-practice-component {
    p {
      font-size: 14px;
      font-weight: 400;
    }

    .believe-practice-content {
      height: 100%;

      .left-content {
        padding: 0;
      }

      .right-content {
        padding: 20px 0;

        .signup-btn {
          margin-top: 15px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}