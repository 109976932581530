.input-component {
  width: 100%;
  position: relative;

  .input-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 15px;
    border: solid 1px var(--gray700);

    ::placeholder {
      color: var(--blue900);
      opacity: 0.6;
    }

    .label {
      font-size: 12px;
      color: var(--blue900);
      opacity: 0.6;
    }

    .input-content {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      
      .left-icon {
        margin-right: 5px;
      }

      .label-input {
        width: 100%;

        .main-input {
          flex: 1;
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          background: none;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .input-container {
    height: 60px;

    .label {
      margin-top: 3px;
    }

    .main-input {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 991px) {
  .input-container {
    height: 50px;

    .label {
      margin-top: 2px;
    }

    .main-input {
      font-size: 14px;
    }
  }
}