.drop-down-select-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  width: 100%;
  border: solid 1px var(--gray700);
  padding: 0 15px;
  position: relative;

  .select-header {
    justify-content: space-between;
  }

  .label {
    font-size: 12px;
    color: var(--blue900);
    opacity: 0.6;
  }

  .placeholder-des {
    font-size: 16px;
    color: var(--blue900);
    opacity: 0.3;
  }

  .value {
    font-size: 16px;
  }

  .drop-list-container {
    position: absolute;
    top: 60px;
    left: 0px;
    right: 0;
    background-color: var(--white900);
    z-index: 3;
    height: 300px;
    overflow-y: scroll;

    .drop-list-item {
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        background-color: var(--gray700);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .drop-down-select-component {
    height: 50px;

    .value {
      font-size: 14px;
    }

    .drop-list-container {
      height: 200px;
      font-size: 14px;
    }
  }
}