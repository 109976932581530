.question-answer-header-component {
  margin-bottom: 36px;

  .back-btn {
    color: var(--main-color);
    background-color: var(--blue800_10);
  }

  .skip-btn {
    margin-right: 10px;
    background-color: #F9FAFB;
    color: var(--main-color);
  }

  .step-num {
    font-size: 12px;
    opacity: 0.6;

    strong {
      opacity: 1;
    }
  }
}