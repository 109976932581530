.signup-payment {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-size: 34px;
    font-weight: 700;
  }

  .amount-header {
    .sub-title {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .policy-content {
    display: flex;
    margin-top: 20px;

    .policy-text {
      font-size: 14px;
      color: var(--gray100);

      .email-link {
        margin: 0 5px;
      }
    }
  }

  .done-btn {
    margin: 10px 0;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .signup-payment {
    gap: 10px;

    .exp-date {
      margin-bottom: 10px;
    }
  }
}