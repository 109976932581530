.testimonial-item-component {
  color: var(--blue900);

  .left-content {
    height: 100%;
    padding-left: 0;

    .thumb-img {
      max-height: 300px;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 991px) {
  .testimonial-item-component {
    .left-content {
      padding: 0;
      margin-bottom: 20px;
    }
  }
}