.auth-container {
  min-height: 100vh;
  width: 100%;
  margin-top: -87px;
  display: flex;

  h1 {
    color: var(--main-color);
  }

  .ads-area {
    display: flex;
    flex-direction: column;
    position: relative;
    background: no-repeat url('../../../Assets/Images/Auth/adsbg.png');
    background-position: center;
  }

  .auth-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .main-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      .continue-btn {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .auth-container {
    .auth-content {
      .main-col {
        padding: 0 15px !important;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .auth-container {
    h1 {
      font-size: 36px;
    }

    .ads-area {
      width: 492px;
      background-size: cover;
      color: white;
      justify-content: center;
      text-align: center;
      padding: 0 43px;
      font-size: 15px;
    }

    .auth-content {
      .main-col {
        padding: 0 117px;

        .auth-input {
          height: 65px;
          margin-top: 30px;
        }

        .continue-btn {
          width: 200px;
          margin-left: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .auth-container {
    flex-direction: column-reverse;

    h1 {
      font-size: 26px;
      text-align: center;
    }

    .ads-area {
      background-size: 100% auto;
      padding: 30px;
    }

    .auth-input {
      margin-top: 15px;
    }
  }
}