.help-you-component {
  color: var(--white900);
  background-color: var(--blue900);

  h1 {
    white-space: pre-line;

    strong {
      color: var(--green800);
    }
  }

  h3 {
    strong {
      color: var(--green800);
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 15px;
  }

  .help-you-content {
    .left-content {
      height: 100%;
      padding-left: 0;

      .decide-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .detail-list {
          display: flex;
          flex-direction: column;
          gap: 30px;

          .detail-item {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            font-size: 16px;

            .b-icon {
              margin-right: 20px;
            }

            .list-sub-title {
              font-size: 24px;
            }
          }
        }

        .access-section {
          font-size: 24px;
        }
      }

      .upgrade-btn {
        margin-top: 40px;
        background-color: var(--green800);
        color: var(--blue900);
      }
    }

    .right-content {
      .book-img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .help-you-component {
    padding: 15px;

    p {
      font-size: 14px;
      font-weight: 400;
    }

    .help-you-content {
      height: 100%;

      .left-content {
        padding: 15px;
        order: 2;

        .decide-content {
          .detail-list {
            .detail-item {
              .b-icon {
                width: 25px;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .right-content {
        padding: 20px;

        .book-img {
          width: 100%;
        }
      }
    }
  }
}