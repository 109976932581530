.testimonial-component {
  color: var(--blue900);

  h3 {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }

  .testimonial-content {
    max-width: 1052px;
    margin-left: auto;
    padding: 0;

    .owl-theme {
      .owl-nav {

        .owl-prev,
        .owl-next {
          outline: none;

          &:hover {
            background: white;
          }
        }

        .owl-prev {
          margin: 0 15px 0 5px;
        }

        .owl-next {
          margin: 0 5px 0 15px;
        }
      }

      .owl-stage-outer {
        .owl-stage {
          display: flex;
          padding-left: 38px !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .testimonial-component {

    .testimonial-content {
      height: 100%;

      .owl-theme {
        .owl-stage-outer {
          .owl-stage {
            display: flex;
            padding-left: 20px !important;
            padding-right: 20px !important;
          }
        }
      }
    }
  }
}