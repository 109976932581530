.question-answer-modal-component {
  .modal-dialog {
    width: 100%;
    margin: 0;
    height: 100%;
    max-width: 100%;

    .modal-content {
      border: none;
      outline: none;
      background: none;
      position: relative;
      height: 100%;

      .close-btn {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: -25px;
        fill: white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-close {
          width: 100%;
          height: 100%;

          div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .question-answer-modal-content {
    display: flex;
    margin: auto;
    background: var(--white900);
    position: relative;
    width: 100%;
    height: 80vh;
    padding: 30px;
    
    .question-answer-main-content {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}

@media screen and (min-width: 992px) {
  .question-answer-modal-component {
    .modal-content {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .question-answer-modal-component {

    .modal-content {
      padding: 0 15px;
    }

    .question-answer-modal-content {
      width: 100%;
      height: 80%;
      padding: 15px;

      .question-answer-main-content {
        overflow-y: scroll;
      }
    }
  }
}