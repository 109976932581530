@import '~bootstrap/scss/bootstrap';
@import './Assets/scss/index.scss';


.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  color: var(--blue900);

  #main {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    .main-content {
      display: flex;
      flex-direction: column;
    }

    &.blur {
      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        // background-color: rgba(black, 0.45);
        z-index: 15;
      }
    }
  }
}

.signup-university {

  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: 0;
  @media screen and (max-width: 991px) {
    position: relative;
  }
}

@media screen and (min-width: 992px) {
  .main-content {
    padding-top: 127px;
  }
}

@media screen and (max-width: 991px) {
  .main-content {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.overlay-loader {
  z-index: 1010;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}