:root {
  --main-color: #22A4EF;

  --green700: #78C665;
  --green800: #8ED37D;
  --green800_10: rgba(142, 211, 125, 0.1);
  --green900: #24C1A5;
  --green900_10: rgba(36, 193, 165, 0.1);
  --green900_15: rgba(36, 193, 165, 0.15);

  --white100: rgba(255, 255, 255, 0.1);
  --white600: rgba(255, 255, 255, 0.6);
  --white700: rgba(255, 255, 255, 0.7);
  --white900: #fff;

  --black900: #000;

  --blue300: #3A1AAA;
  --blue500: #224FEF;
  --blue600: #252766;
  --blue600_60: rgba(37, 39, 102, 0.6);
  --blue400: #42BAFF;
  --blue700: #23A4EF;
  --blue800: #22A4EF;
  --blue800_10: rgba(34, 164, 239, 0.14);
  --blue800_15: rgba(34, 164, 239, 0.15);
  --blue900: #000554;
  --blue900_10: rgba(2, 5, 75, 0.1);
  --blue900_40: rgba(2, 5, 75, 0.4);
  --blue900_60: rgba(2, 5, 75, 0.6);

  --gray100: #A6AFC0;
  --gray200: #F8F8FC;
  --gray300: #EBF0F5;
  --gray400: #EBF0F5;
  --gray600: #A6A9AB;
  --gray600_59: rgba(166, 169, 171, 0.59);
  --gray700: #E7EBEE;
  --gray700_41: rgba(231, 235, 238, 0.41);
  --gray700_50: rgba(231, 235, 238, 0.5);
  --gray800: #F9FAFB;

  --yellow600: #FFDF6E;
  --yellow600_10: rgba(255, 223, 110, 0.1);
  --yellow800: #F08C17;
  --yellow900: #F3CD69;

  --red300: #F341F3;
  --red900: #f20519;
  --red900_10: rgba(242, 5, 25, 0.1);
}
