.answer-item-component {
  .answer {
    position: relative;
    padding: 15px;
    border: solid 1px var(--gray700);
    margin: 20px 0;
    cursor: pointer;

    &.correct-answer-content {
      border: solid 1px var(--green900);
      background-color: var(--green900_10);
    }

    &.inCorrect-answer-content {
      border: solid 1px var(--red900);
      background-color: var(--red900_10);
    }

    .answer-title {
      font-size: 16px;
      font-weight: 500;

      .check-content {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: solid 1px var(--gray700);
        margin-right: 10px;

        &.select-check-content {
          background-color: var(--gray700);
          border: none;
        }

        &.correct-check-content {
          background-color: var(--green900);
          border: none;
        }

        &.inCorrect-check-content {
          background-color: var(--red900);
          border: none;
        }

        svg {
          margin-top: -3px;
          margin-left: 1px;
        }
      }
    }

    .see-video {
      font-size: 12px;
      color: var(--main-color);
      margin-left: 10px;
    }

    .funny-icon-1 {
      width: 50px;
      position: absolute;
      right: -20px;
      top: -18px;
    }

    .funny-icon-2 {
      width: 33px;
      position: absolute;
      right: -13px;
      bottom: -15px;
    }

    .funny-icon-3 {
      transform: rotate(-15deg);
      width: 27px;
      position: absolute;
      right: 39px;
      top: -13px;
    }
  }
}