.our-partners {
  .partners-content {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);

    .item-content {
      background-color: var(--white900);
      width: 100%;
      height: 120px;
    }
  }
}

@media screen and (max-width: 991px) {
  .our-partners {
    .partners-content {
      display: grid;
      gap: 1.5rem;
      grid-template-columns: repeat(2, 1fr);

      .item-content {
        background-color: var(--white900);
        height: 70px;
        padding: 20px;

        svg {
          width: 100%;
        }
      }
    }
  }
}