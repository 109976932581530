.cancelation-component {
  color: var(--white900);
  background-color: var(--blue500);

  h1 {
    strong {
      color: var(--green800);
    }
  }

  h2 {
    text-align: start !important;

    strong {
      color: var(--green800);
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }

  .cancelation-content {
    .left-content {
      .cancelation-content {
        max-width: 580px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .right-content {
      justify-content: flex-end;

      .cancelation-img {
        max-width: 459px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .cancelation-component {
    padding: 15px;

    p {
      font-size: 14px;
      font-weight: 400;
    }

    .cancelation-content {
      height: 100%;

      .left-content {
        padding: 15px;
        order: 2;
      }

      .right-content {
        padding: 20px;

        .cancelation-img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}