.faqs {
  background-color: var(--gray200);

  .faqs-container {
    .faqs-list {
      background-color: var(--white900);
      width: 100%;
      margin-top: 50px;

      .accordion {
        border-bottom: 1px solid #E7EBEE;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .more-btn {
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
      background-color: var(--green100);
      color: var(--main-color);
      margin-top: 30px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .faqs {
    .faqs-container {
      max-width: 930px;
    }
  }
}

@media screen and (min-width: 992px) {
  .faqs {
    .faqs-container {
      .faqs-list {
        padding: 0 30px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .faqs {
    .faqs-container {
      .faqs-list {
        padding: 0 15px;
        margin-top: 20px;
      }
    }
  }
}