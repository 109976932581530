.plan-component {
  padding: 15px;
  border-top: solid 2px;
  position: relative;

  span {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &.border-top-1 {
    border-color: var(--yellow800);

    span {
      color: var(--yellow800);
    }
  }

  &.border-top-2 {
    border-color: var(--blue900);

    span {
      color: var(--blue900);
    }
  }

  &.border-top-3 {
    border-color: var(--green900);

    span {
      color: var(--green900);
    }
  }

  &.border-top-4 {
    border-color: var(--main-color);

    span {
      color: var(--main-color);
    }
  }

  &.active {
    color: var(--white900);
    background-color: var(--main-color);

    span {
      color: var(--white900) !important;
    }

    .chose-btn {
      background-color: var(--white900) !important;
    }
  }

  .plan-main-content {
    .price {
      font-size: 20px;
      font-weight: 700;
      margin: 15px 0 25px;

      &.disable-price {
        color: var(--gray600);
        text-decoration: line-through;
      }
    }

    .discount {
      font-size: 26px;
      font-weight: 700;
      color: var(--green800);
      margin-top: -20px;
    }

    .chose-btn {
      background-color: var(--blue800_10);
      color: var(--main-color);
      font-size: 16px;
      font-weight: 500;
    }

    .split-border {
      border-top: solid 1px var(--gray700);
      margin: 20px 0;
    }

    .access-permission {
      font-size: 14px;
      margin-left: 15px;
    }
  }

  .hit-bg {
    position: absolute;
    top: 13px;
    right: -4px;

    &.trial-bg {
      top: 53px;
    }

    .hit-text {
      margin-top: -33px;
      margin-left: 25px;
      font-size: 16px;
      font-weight: 700;
      color: var(--white900);
    }
  }
}
