.we-pay-component {
  color: var(--white900);
  background-color: var(--blue300);

  h1 {
    white-space: pre-line;

    strong {
      color: var(--green800);
    }
  }

  h3 {
    strong {
      color: var(--green800);
    }
  }

  .pay-now-content {
    .left-content {
      height: 100%;
      padding-left: 0;

      .comment-section {
        border: solid 1px var(--green800);
        padding: 30px 25px;
        font-size: 20px;
        font-style: italic;
        margin: 36px 0 28px 0;
      }

      .upgrade-btn {
        margin-top: 40px;
        background-color: var(--green800);
        color: var(--blue900);
        border-radius: 8px;
      }
    }

    .right-content {
      display: flex;
      justify-content: flex-end;

      .we-pay-right-img {
        max-width: 380px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .we-pay-component {
    padding: 15px;

    .pay-now-content {
      height: 100%;

      .left-content {
        padding: 15px;
        order: 2;
      }

      .right-content {
        padding: 20px;

        .we-pay-right-img {
          width: 100%;
        }
      }
    }
  }
}