.question-answer-component {
  padding-top: 15px !important;
  color: var(--blue900);

  h3 {
    font-size: 24px;
  }

  .sub-header-content {
    height: 60px;
  }

  .right-content {
    .coming-soon {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      margin-top: 100px;
    }
  }
}

@media screen and (max-width: 991px) {
  .question-answer-component {
    h3 {
      font-size: 20px;
    }

    .right-content {
      padding: 15px;
    }
  }
}