.reviews {
  img {
    width: 10px !important;
  }

  .reviews-container {
    .main-row {
      padding: 0;

      .owl-theme {
        .owl-nav {

          .owl-prev,
          .owl-next {
            outline: none;

            &:hover {
              background: none;
            }
          }
        }

        .owl-stage-outer {
          padding-top: 30px;
          padding-bottom: 30px;

          .owl-stage {
            display: flex;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .reviews-container {
    max-width: 1190px;
  }
}

@media screen and (min-width: 992px) {
  .reviews {
    padding: 50px 0;

    .reviews-container {
      .main-row {
        .owl-theme {
          .owl-nav {
            position: absolute;
            display: flex;
            justify-content: space-between;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            left: -30px;
            right: -30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .reviews {
    padding: 10px 0;

    .reviews-container {
      padding: 15px;

      .main-row {
        .owl-theme {
          .owl-nav {

            .owl-prev,
            .owl-next {
              outline: none;

              &:hover {
                background: white;
              }
            }

            .owl-prev {
              margin: 0 15px 0 5px;
            }

            .owl-next {
              margin: 0 5px 0 15px;
            }
          }
        }
      }
    }
  }
}