.love-us-component {
  color: var(--blue900);

  h2 {
    text-align: start !important;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }

  .love-us-content {
    max-width: 1052px;

    .left-content {
      padding-left: 0;
    }

    .right-content {
      display: flex;
      gap: 20px;

      .review-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .love-us-component {
    p {
      font-size: 14px;
      font-weight: 400;
    }

    .love-us-content {

      .left-content {
        padding: 0;
      }

      .right-content {
        padding: 20px 0;
      }
    }
  }
}