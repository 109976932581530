.up-selling-container-component {  
  height: calc(100vh - 127px);

  h1 {
    font-size: 44px;
    text-align: center;
  }

  .up-selling-list-content {
    width: 570px;
    padding-top: 45px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}

@media screen and (max-width: 991px) {
  .up-selling-container-component {
    padding: 15px;

    h1 {
      font-size: 32px;
    }

    .up-selling-list-content {
      width: 100%;
    }
  }
}