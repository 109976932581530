.make-it-sense-page {

  .landing-part {
    padding: 80px 0;

    h1 {
      font-size: 64px;
      font-weight: 700;
    }

    h2 {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 30px;
    }

    h3 {
      font-size: 32px;
    }

    @media screen and (max-width: 991px) {
      padding: 50px 0;

      h1 {
        font-size: 44px;
      }

      h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px;
      }

      h3 {
        font-size: 20px;
      }
    }
  }
}