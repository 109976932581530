.select-btn {
  background-color: var(--gray200);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &.active {
    background-color: var(--yellow900);
  }
}

@media screen and (min-width: 992px) {
  .select-btn {
    width: 286px;
    height: 64px;
    padding: 20px;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 991px) {
  .select-btn {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;

    .title {
      font-size: 13px;
      font-weight: 400;
    }

    .arrow-btn {
      width: 7px;
    }
  }
}