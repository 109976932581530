.subject-item-component {
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
  
  .subject-name {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}