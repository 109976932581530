.login-component {
  .action-area {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sign-up {
      a {
        &.active {
          text-decoration: none;
        }
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &.active {
        text-decoration: none;
      }
    }
  } 
}