.free-book-component {
  color: var(--white900);
  background: url('../../../Assets/Images/Landing/Ebook/eBook-bg.png') no-repeat;
  background-position: center bottom;
  background-size: 100% 100%;

  h1 {
    font-size: 72px;
    margin-right: 15px;
  }

  h2 {
    text-align: start !important;

    strong {
      color: var(--blue400);
    }
  }



  .free-book-content {
    .left-content {
      height: 100%;
      padding-left: 0;

      .list-content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h5 {
          font-size: 22px;
          font-weight: 400;
        }

        p {
          font-size: 16px;
          font-weight: 400;

          strong {
            color: var(--green800);
          }
        }
      }

      .download-btn {
        margin-top: 40px;
        background-color: var(--green800);
        color: var(--blue900);

        .down-btn-text {
          margin-left: 10px;
        }
      }
    }

    .right-content {
      justify-content: flex-end;

      .ebook-img {
        max-width: 380px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .free-book-component {
    padding: 15px;

    p {
      font-size: 14px;
      font-weight: 400;
    }

    .free-book-content {
      height: 100%;

      .left-content {
        padding: 15px;
        order: 2;
      }

      .right-content {
        padding: 20px;

        .ebook-img {
          width: 100%;
        }
      }
    }
  }
}