.plan-modal-component {
  .modal-dialog {
    width: 100%;
    margin: 0;
    height: 100%;
    max-width: 100%;

    .modal-content {
      border: none;
      outline: none;
      background: none;
      position: relative;
      height: 100%;

      .close-btn {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }
  }

  .plan-modal-content {
    display: flex;
    margin: auto;
    background: var(--white900);
    position: relative;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .modal-content {
    padding: 0 30px;
  }
}

@media screen and (max-width: 991px) {
  .modal-content {
    padding: 0 15px;
  }

  .plan-modal-content {
    width: 80%;
    height: 80%;

    .close-btn {
      top: 5px !important;
      right: 5px !important;

      .close-icon {
        width: 25px;
      }

    }

    .plan-main-content {
      overflow-y: scroll;
    }
  }
}
