.review-component {
  background: white;
  width: 100%;
  height: 100%;
  box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.05);
  color: var(--blue900);

  .review-header {
    .title {
      font-size: 20px;
      font-weight: 700;
      margin: 15px 0;
    }
  }

  .review {
    font-size: 15px;
    line-height: 26px;
    height: 182px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    margin: 0px 0 6px;
  }

  .review-footer {
    padding-top: 20px;

    .avatar-content {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: solid 1px var(--blue900);

      .avatar {
        width: 42px !important;
        height: 42px;
        border-radius: 50%;
      }
    }

    .name-content {
      margin-left: 15px;

      .name {
        font-size: 16px;
        font-weight: 500;
      }

      .address {
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .review-component {
    padding: 30px 20px 20px 30px;
    max-width: 370px;
  }
}

@media screen and (max-width: 991px) {
  .review-component {
    padding: 30px 9px 24px 30px;
    min-width: 335px;
  }
}