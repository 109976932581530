.circle-timer-component {
  width: 80px;
  height: 80px;

  .time {
    font-size: 18px;
    font-weight: 500;
    color: var(--main-color);

    .count-up {
      color: var(--red900);
    }
  }
}

@media screen and (max-width: 991px) {
  .circle-timer-component {}
}