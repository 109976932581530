.practice-item-component {
  cursor: pointer;

  .practice-logo {
    width: 100%;
  }

  .footer {
    padding: 15px;
    background-color: var(--white900);

    .practice-btn {
      font-size: 16px;
      font-weight: 500;
      color: var(--gray600_59);
      background-color: var(--gray700_41);
      padding: 15px 22px;

      &.active {
        color: var(--main-color);
        background-color: var(--blue800_10);
      }

      &.active-btn {
        &:hover {
          background-color: var(--blue800);
          color: var(--white900);
        }

      }
    }
  }
}