.circle-progress {

  .number {
    font-size: 12px;
    font-weight: 500;
  }

  .progress-content {
    display: flex;
    align-items: center;

    .num-content {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: solid 1px var(--gray300);
      margin: 0 5px;
      cursor: pointer;

      &.past {
        background-color: var(--blue900);
        color: var(--white900);
      }

      &.current {
        background-color: var(--yellow900);
        color: var(--white900);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .circle-progress {}
}

@media screen and (max-width: 991px) {
  .circle-progress {}
}