.payment-thank-you-page {
  padding-top: 100px;


  .right-icon {
    position: absolute;
    right: 0px;
    bottom: 0;
  }

  .next-btn-content {
    position: relative;
    z-index: 5;

    .done-btn {
      width: 100%;
    }

    .arrow-icon {
      position: absolute;
      top: -111px;
      left: -166px;
    }
  }
}

@media screen and (max-width: 991px) {
  .payment-thank-you-page {}
}