.score-component {
  padding: 10px 0;
  
  .title {
    font-size: 16px;
    font-weight: 500;
    color: var(--blue900);
  }

  span {
    font-size: 14px;
    color: var(--blue600_60);
  }

  .out-container {
    width: 100%;
    height: 16px;
    border-radius: 33px;
    background-color: var(--gray700);
    margin-top: 10px;
    overflow-x: hidden;

    .inner-container {
      height: 100%;
      background-color: var(--main-color);
      border-radius: 33px;
    }
  }
}