.gain-life-time-component {
  color: var(--white900);
  background-color: var(--blue900);

  h1 {
    font-size: 64px;

    strong {
      color: var(--green800);
    }
  }

  h2 {
    text-align: start !important;

    strong {
      color: var(--green800);
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .gain-life-time-content {
    .left-content {
      height: 100%;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .gain-content {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .detail-list {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .detail-item {
            display: flex;
            gap: 10px;
            font-size: 18px;
          }
        }

        .give-away-section {
          border: solid 1px var(--red300);
          padding: 30px 25px;
          font-size: 20px;
          font-style: italic;
        }

        .access-section {
          font-size: 24px;
        }

      }

      .upgrade-btn {
        background-color: var(--green800);
        color: var(--blue900);
        border-radius: 8px;
      }
    }

    .right-content {
      justify-content: flex-end;

      .key-img {
        max-width: 380px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .gain-life-time-component {
    padding: 15px;

    p {
      font-size: 14px;
      font-weight: 400;
    }

    .gain-life-time-content {
      height: 100%;

      .left-content {
        padding: 15px;
        order: 2;

        .star-icon {
          width: 25px;
        }
      }

      .right-content {
        padding: 20px;

        .key-img {
          width: 100%;
        }
      }
    }
  }
}