.question-summary-component {
  background-color: #13276F;
  padding: 25px;
  color: var(--white900);      

  .right-description-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      opacity: 0.8;
    }

    .questions-num {
      color: var(--green700);
    }
  }

  .btn-2 {
    background-color: var(--green700);
    margin: 20px 0 30px 0;
  }
}