.signup-container {
  padding: 0 0 50px;

  .header-section {
    margin-bottom: 70px;

    h2 {
      font-size: 44px;
      font-weight: 700;
      text-align: center;

      &.free-trial {
        color: var(--blue900_40);
      }
    }

    .page-description {
      text-align: center;
      font-size: 18px;
    }
  }

  .left-content {
    padding: 0 50px;
  }
}

@media screen and (max-width: 991px) {
  .signup-container {
    .header-section {
      margin-bottom: 40px;

      h2 {
        font-size: 24px;
      }
    }

    .left-content {
      padding: 0;
    }

    .right-content {
      padding: 30px 0;
    }
  }
}
