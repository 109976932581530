.question-tags-component {
  display: flex;

  .tag-item {
    padding: 10px 12px;
    border: solid 1px var(--blue800);
    background-color: var(--blue800_10);
    color: var(--blue900);
    font-size: 18px;
    margin-right: 16px;

    &.item-2 {
      border: solid 1px var(--yellow600);
      background-color: var(--yellow600_10);
    }

    &.item-3 {
      flex: 1;
      display: flex;
      border: solid 1px var(--green800);
      background-color: var(--white);
      margin-right: 0;
      border-right: 0;
    }
  }

  .test-rules {
    border: solid 1px var(--green800);
    background-color: var(--green800_10);
    display: flex;
    align-items: center;
    padding: 10px 12px;

    .rules {
      font-size: 18px;
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 991px) {
  .question-tags-component {
    .tag-item {
      margin-right: 5px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .test-rules {
      padding: 10px 6px;

      .rules {
        font-size: 14px;
      }
    }
  }
}