.tutors-from {
  h3 {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.6;
  }

  .brand-content {
    justify-content: space-between;
    padding: 25px 0 40px 0;
  }
}

@media screen and (max-width: 991px) {
  .tutors-from {
    padding-bottom: 30px;
    .brand-content {
      padding: 15px 0;
    }
  }
}